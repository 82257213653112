<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Взаимодействие с внешней средой</h1>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="report">
      <div class="report__list">
        <router-link :to="{ name: 'passport-interaction-advisory' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>1</span>
          </div>
          <div class="report__title">Участие в совещательных, консультативных и экспертных советах при органах
            законодательной и исполнительной властей федерального, межрегионального, регионального уровней
          </div>
        </router-link>
        <router-link :to="{ name: 'passport-interaction-public' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>2</span>
          </div>
          <div class="report__title">Участие в Общественных палатах региона, РФ</div>
        </router-link>
        <router-link :to="{ name: 'passport-interaction-name' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>3</span>
          </div>
          <div class="report__title">Региональный орган государственной власти, осуществляющий управление в сфере
            молодежной политики
          </div>
        </router-link>
        <router-link :to="{ name: 'passport-interaction-support' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>4</span>
          </div>
          <div class="report__title">Формы поддержки РО РСМ от региональных органов государственной власти и органов
            местного самоуправления
          </div>
        </router-link>
        <router-link :to="{ name: 'passport-interaction-partner' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>5</span>
          </div>
          <div class="report__title">Основные партнеры организации</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Passport',
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    regionOrg() {
      return this.$store.state.organization
    },
  },
  methods: {
    onExport() {
      this.$store.dispatch('passport/GET_EXPORT')
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Паспорт_РО_${this.regionOrg}.docx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
}
</script>